// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magento-2-migration-js": () => import("./../../../src/pages/magento-2-migration.js" /* webpackChunkName: "component---src-pages-magento-2-migration-js" */),
  "component---src-pages-magento-support-js": () => import("./../../../src/pages/magento-support.js" /* webpackChunkName: "component---src-pages-magento-support-js" */),
  "component---src-pages-shopify-plus-js": () => import("./../../../src/pages/shopify-plus.js" /* webpackChunkName: "component---src-pages-shopify-plus-js" */),
  "component---src-pages-shopify-support-js": () => import("./../../../src/pages/shopify-support.js" /* webpackChunkName: "component---src-pages-shopify-support-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-the-benefits-of-migrating-magento-1-to-magento-2-js": () => import("./../../../src/pages/the-benefits-of-migrating-magento-1-to-magento-2.js" /* webpackChunkName: "component---src-pages-the-benefits-of-migrating-magento-1-to-magento-2-js" */),
  "component---src-pages-website-design-js": () => import("./../../../src/pages/website-design.js" /* webpackChunkName: "component---src-pages-website-design-js" */),
  "component---src-templates-blog-pages-js": () => import("./../../../src/templates/blog-pages.js" /* webpackChunkName: "component---src-templates-blog-pages-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

